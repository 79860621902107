<template>
   <div class="card mb-0" ref="pdv" @click="abrir" :index="index" :class="[index % 2 == 0 ? '' : 'invert', 
      (pdv.periodoAberto && pdv.idOperador == dadosUsuario.usuarioId) || !pdv.periodoAberto || dadosUsuario.usuarioPermissoes.includes('Caixa | Abrir/Fechar caixa') ? 'cursor-pointer' : '']">
      <div class="card-body text-center p-12">
         <h5 class="font-15 mb-1 limitador-1" :class="pdv.periodoAberto ? '' : 'text-red mt-2'">{{ pdv.nome }}</h5>
         <p class="font-12 mb-0" v-if="pdv.periodoAberto">
            <span class="limitador-1">
               <i class="far fa-user font-10 me-1" :class="pdv.periodoAberto ? (pdv.idOperador == dadosUsuario.usuarioId ? 'text-green' : 'text-yellow') : 'text-red'"></i>
               <span> {{ pdv.nomeOperador == null ? 'Desconhecido' : pdv.nomeOperador }}</span>
            </span>
         </p>
         <p class="font-12" :class="pdv.periodoAberto ? 'mb-0' : 'mb-2'">
            <span class="limitador-1" v-if="pdv.periodoAberto"><i class="fas fa-check font-11 text-green me-1"></i>Aberto</span>
            <span class="limitador-1" v-else><i class="far fa-times text-red me-1"></i>Fechado</span>
         </p>
      </div>
   </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'Pdv',
   props: ['pdv', 'index'],
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario
      })
   },
   methods: {
      abrir : function () {
         if ((this.pdv.periodoAberto && this.pdv.idOperador == this.dadosUsuario.usuarioId)) {
            this.$emit('abrir', this.pdv)

         } else if (this.pdv.periodoAberto && this.dadosUsuario.usuarioPermissoes.includes('Caixa | Abrir/Fechar caixa')) {
            this.$emit('abrir', this.pdv)

         } else if (!this.pdv.periodoAberto) {
            this.$emit('abrir', this.pdv)
         }
      }
   }
}

</script>