<template>
   <div class="card mb-1">
		<div class="card-body p-12">
         <div class="row">
            <div class="col-12 mb-2 align-self-center">
               <div class="limitador-1 mb-1">
                  <i class="far fa-store color-theme font-13 me-1"></i><strong class="subtitle font-15 text-capitalize"> {{ loja.nomeFantasia }}</strong>
                  <small class="mx-2">|</small><span class="font-13"><i class="far fa-tag font-11 me-1"></i> {{ loja.numeroLoja }}</span>
               </div>
            </div>

            <div class="col-12">
               <div class="cards-150 cards-sm-175">
                  <pdv v-for="(pdv, index) in loja.pdvs" :key="index" :index="index" :pdv="pdv" @abrir="abrirPdv($event)" />
               </div>
            </div>
   
            <div class="col-12 my-5 text-center" v-if="loja.pdvs.length == 0">
               <i class="fal fa-store-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
               <h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum caixa encontrado</h4>
            </div>
         </div>
      </div>
	</div>
</template>

<script>

import pdv from '@/components/pdv/caixas/Pdv.vue'

export default {
	name: 'Loja',
	props: ['loja', 'index'],
   components: {
      pdv
   },
   methods: {
      abrirPdv : function (pdv) {
         pdv.loja = this.loja.numeroLoja
         this.$emit('abrirPdv', pdv)
      }
   }
}

</script>