<template>
	<div class="row">
      <div class="col-12" v-if="telaAjuda[$route.path.substring(1, $route.path.lastIndexOf('/'))]">
         <div class="alert alert-secondary alert-dismissible fade show mb-2" role="alert">
            <strong>{{ $route.name != null ? $route.name.replace(/_/g, " ").replace(/-/g, "/") : '' }}</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
            <span>Controle caixas abertos em suas lojas, clique sobre ele para acessá-lo.</span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="$store.dispatch('toggleTelaAjuda', $route.path.substring(1, $route.path.lastIndexOf('/')))"></button>
         </div>
      </div>

		<div class="col-12" v-if="pesquisa.resultado.length > 0">
         <loja v-for="(loja, index) in pesquisa.resultado" :key="index" :loja="loja" :index="index" @abrirPdv="permissaoAbrirPdv($event)" />
      </div>
	</div>
</template>

<script>

import $ from 'jquery'
import router from '@/router'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import loja from '@/components/pdv/caixas/Loja.vue'

export default {
   name: 'Caixas',
   data: function () {
      return {
         pesquisa: {'resultado': []},
			caixa: {}
		}
	},
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
			telaAjuda: state => state.telaAjuda,
         urlRest: state => state.urlRest,
         tabs: state => state.tabs
      })
   },
   watch: {
		$data: {
			handler: function(value) {
				this.$store.dispatch('attTabData', JSON.parse(JSON.stringify({'id': this.$route.params.idTab, 'data': value})))
			},
			deep: true
		},
		'$route.params.idTab': function () {
         this.buscarLojas()
      }
   },
   components: {
      loja
   },
   methods: {
		buscarLojas : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)
			Object.assign(this.$data.pesquisa, this.$options.data.apply(this).pesquisa)

			this.$store.dispatch('getLojas', false).then((data) => {
				this.pesquisa.resultado = data
			}).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
      },
      permissaoAbrirPdv : function (caixa) {
			if (caixa.periodoAberto) {
				let tabCaixa = this.tabs.find(tab => tab.nome == 'Caixa' && tab.query.idPdv == caixa.id)
				router.push('/caixa/'+ (tabCaixa ? tabCaixa.id : this.tabs.length) +'?idPdv='+ caixa.id)
				
			} else {
				if (this.dadosUsuario.usuarioPermissoes.includes('Caixa | Abrir/Fechar caixa')) {
					this.abrirPeriodoPdv(this.dadosUsuario.usuarioId, caixa)
				} else {
					this.$store.dispatch('validarSupervisor').then((idSupervisor) => this.abrirPeriodoPdv(idSupervisor, caixa))
				}
			}
		},
		abrirPeriodoPdv : function (idSupervisor, caixa) {
			Swal.fire({
				icon: 'warning',
				title: 'Abrir caixa',
				text: 'Informe o saldo inicial',
				showCancelButton: true,
				confirmButtonText: 'Confirmar',
				cancelButtonText: `Cancelar`,
				input: 'text',
				inputPlaceholder: 'Valor em dinheiro',
				didOpen: (el) => {
					$(el.querySelector(".swal2-input")).keyup((v) => {
						this.$store.dispatch('formatValue', {'valor': String(v.target.value).replace(",", "."), 'digitos': 2}).then((valor) => {
							v.target.value = valor
						})
					});
				},
				inputValidator: (value) => {
					if (!value) {
						return 'Valor inválido'
					}
				}
			}).then((result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('alternarTelaCarregamento', true)

					this.$axios({
						method: 'post',
						url: this.urlRest +'pdv/savePeriodo',
						headers: {'Content-Type': 'application/json'},
						data: {"idPdv": caixa.id, "idSupervisor": idSupervisor, "aberto": true, "valor": result.value}
						
					}).then(() => {
						let tabCaixa = this.tabs.find(tab => tab.nome == 'Caixa' && tab.query.idPdv == caixa.id)
						router.push('/caixa/'+ (tabCaixa ? tabCaixa.id : this.tabs.length) +'?idPdv='+ caixa.id)
						this.$store.dispatch('saveUsuarioPdv', caixa.id)

						this.$toast.fire({
                     icon: 'success',
                     title: 'Caixa aberto!'
                  });
					}).catch((error) => {
						if (error.response != undefined) {
							if (error.response.status == 408) {
								this.$store.dispatch('deslogar')

								this.$toast.fire({
									icon: 'warning',
									title: 'Sessão expirada!'
								});
							} else {
								this.$toast.fire({
									icon: 'error',
									title: 'Erro: ' + error.response.status
								});
							}
						}
					}).finally(() => {
						this.$store.dispatch('alternarTelaCarregamento', false)
					});
				}
			});
		}
	},
   mounted() {
      this.buscarLojas()
   }
}

</script>